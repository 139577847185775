import PropTypes from 'prop-types';

import { useFormContext, Controller } from 'react-hook-form';

import { FormHelperText } from '@mui/material';

import { UploadAvatar, UploadBox, UploadMultiFile, UploadSingleFile } from '../upload';
import UploadCsvFile from '../upload/UploadCsvFile';

RHFUploadAvatar.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
};

export function RHFUploadAvatar({ children, name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <div>
            <UploadAvatar error={checkError} {...other} file={field.value}>
              {children}
            </UploadAvatar>
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}

RHFUploadSingleFile.propTypes = {
  isIllustration: PropTypes.bool,
  name: PropTypes.string,
};

export function RHFUploadSingleFile({ isIllustration, name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleFile
            name={name}
            accept="image/*"
            file={field.value}
            error={checkError}
            isIllustration={isIllustration}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadMultiFile.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  files: PropTypes.array,
};

export function RHFUploadMultiFile({ name, children, files, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = (!!error && field.value?.length === 0) || !!error;
        return (
          <UploadMultiFile
            accept="image/*"
            files={files}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          >
            {children}
          </UploadMultiFile>
        );
      }}
    />
  );
}

RHFUploadCsvFile.propTypes = {
  name: PropTypes.string,
  isIllustration: PropTypes.bool,
  errorMessage: PropTypes.node,
};

export function RHFUploadCsvFile({ name, isIllustration, errorMessage, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = (!!error && !field.value) || !!errorMessage;

        return (
          <UploadCsvFile
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            file={field.value}
            isIllustration={isIllustration}
            error={checkError}
            onChange={(e) => field.onChange(e.target.files[0])}
            helperText={
              checkError &&
              (errorMessage || (
                <FormHelperText error sx={{ px: 2 }}>
                  {error && error.message}
                </FormHelperText>
              ))
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadBox.propTypes = {
  name: PropTypes.string,
  errorMessage: PropTypes.string,
};

export function RHFUploadBox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBox
          files={field.value}
          error={!!error}
          helperText={
            !!error && (
              <FormHelperText error sx={{ px: 2 }}>
                {error?.message}
              </FormHelperText>
            )
          }
          {...other}
        />
      )}
    />
  );
}
