import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { dispatch, useSelector } from 'redux/store';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { Box, IconButton, Link, ListItemText, Tooltip } from '@mui/material';
import { PATH_DASHBOARD } from 'routes/paths';
import { getCallRequest, getCallRequestOpenCount } from 'redux/slices/callRequest';

import BadgeStatus from 'components/BadgeStatus';
import Iconify from 'components/Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';
import { isExternalLink } from '..';

NavItemRoot.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }) {
  const { title, path, icon, info, children } = item;
  const { hasNewMessage } = useSelector((state) => state.chat);
  const { callRequestOpenCount } = useSelector((state) => state.callRequest);

  const { pathname } = useLocation();

  const onRefreshCallRequestCounter = (event) => {
    event.preventDefault();
    dispatch(getCallRequestOpenCount());
    if (pathname === PATH_DASHBOARD.callRequest.root) dispatch(getCallRequest());
  };

  const test = (event) => {
    event.stopPropagation();
    document.querySelectorAll('.MuiButtonBase-root').forEach((button) => {
      const buttonText = button.querySelector('.MuiListItemText-root')?.textContent.trim();

      if (buttonText === 'Գործընկերներ') {
        button.style.color = '';
        button.style.backgroundColor = '';
      }
    });
  };

  const renderContent = (
    <>
      {icon && (
        <>
          {(path === PATH_DASHBOARD.callRequest.root && callRequestOpenCount) ||
          (path === PATH_DASHBOARD.issues.root && hasNewMessage) ? (
            <>
              <ListItemIconStyle sx={{ mr: 0 }}>{icon}</ListItemIconStyle>
              <BadgeStatus status="unread" size="small" sx={{ mr: 1, mb: 2 }} />
            </>
          ) : (
            <ListItemIconStyle>{icon}</ListItemIconStyle>
          )}
        </>
      )}
      <ListItemTextStyle disableTypography primary={title} isCollapse={isCollapse} />
      {path === PATH_DASHBOARD.callRequest.root && !isCollapse && (
        <Tooltip title="Թարմացնել զանգերը">
          <IconButton onClick={(event) => onRefreshCallRequestCounter(event)}>
            <Iconify icon={'eva:refresh-fill'} />
          </IconButton>
        </Tooltip>
      )}
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeRoot={active}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active} onClick={(event) => test(event)}>
      {renderContent}
    </ListItemStyle>
  );
}

NavItemSub.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemSub({ item, open = false, active = false, onOpen }) {
  const { title, path, info, children } = item;

  const test = () => {
    document.querySelectorAll('.MuiButtonBase-root').forEach((button) => {
      const buttonText = button.querySelector('.MuiListItemText-root')?.textContent.trim();

      if (buttonText === 'Գործընկերներ') {
        button.style.color = '#00AB55';
        button.style.backgroundColor = 'rgba(0, 171, 85, 0.08)';
      } else {
        button.style.color = '';
        button.style.backgroundColor = '';
      }
    });
  };

  useEffect(() => {
    if (!open && active) test();
  }, []);

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={title} onClick={(event) => test(event)} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener" subItem>
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
